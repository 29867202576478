<script>
  import { state } from "../stores/state.js"
  import { totals } from "../stores/totals.js"
</script>

{#if $state.roundsRemaining === 0}
  <div class="my-6">
    <div class="text-gray-500 text-center text-4xl font-roboto_bold">
      Game Over
    </div>
    <div class="text-gray-500 text-center text-3xl font-roboto_bold mt-6">
      {$totals.winner}
    </div>
    <div class="flex my-6 justify-center">
      <button
        on:click={state.startNewGame}
        class="w-48  p-2 border-none rounded text-white font-nunito_bold bg-emerald-500 hover:bg-emerald-900 cursor-pointer"
      >
        Start New Game
      </button>
    </div>
  </div>
{/if}
