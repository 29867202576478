<script>
  import { state } from "../stores/state.js"
  import { totals } from "../stores/totals.js"
  import ScoreCardItemPlayer from "../components/ScoreCardItemPlayer.svelte"
  import ScoreCardItemComputer from "../components/ScoreCardItemComputer.svelte"
  import ScoreCardTotalItem from "../components/ScoreCardTotalItem.svelte"
</script>

<div class="grid grid-cols-4 gap-1">
  <div class="bg-black text-white font-nunito_bold text-center">Player</div>
  <div class="col-span-2 bg-black text-white font-nunito_bold text-center">
    {$state.roundsRemaining} rounds remaining
  </div>
  <div class="bg-black text-white font-nunito_bold text-center">Computer</div>
  <div class="">
    <ScoreCardItemPlayer
      category="ones"
      rollScore={$state.rollScore.player.scorecard.ones}
      keptScore={$state.gameScore.player.ones}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Ones</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.ones}
      keptScore={$state.gameScore.computer.ones}
    />
  </div>

  <div class="">
    <ScoreCardItemPlayer
      category="twos"
      rollScore={$state.rollScore.player.scorecard.twos}
      keptScore={$state.gameScore.player.twos}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Twos</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.twos}
      keptScore={$state.gameScore.computer.twos}
    />
  </div>

  <div class="">
    <ScoreCardItemPlayer
      category="threes"
      rollScore={$state.rollScore.player.scorecard.threes}
      keptScore={$state.gameScore.player.threes}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Threes</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.threes}
      keptScore={$state.gameScore.computer.threes}
    />
  </div>
  <div class="">
    <ScoreCardItemPlayer
      category="fours"
      rollScore={$state.rollScore.player.scorecard.fours}
      keptScore={$state.gameScore.player.fours}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Fours</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.fours}
      keptScore={$state.gameScore.computer.fours}
    />
  </div>
  <div class="">
    <ScoreCardItemPlayer
      category="fives"
      rollScore={$state.rollScore.player.scorecard.fives}
      keptScore={$state.gameScore.player.fives}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Fives</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.fives}
      keptScore={$state.gameScore.computer.fives}
    />
  </div>

  <div class="">
    <ScoreCardItemPlayer
      category="sixes"
      rollScore={$state.rollScore.player.scorecard.sixes}
      keptScore={$state.gameScore.player.sixes}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Sixes</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.sixes}
      keptScore={$state.gameScore.computer.sixes}
    />
  </div>

  <div class="">
    <ScoreCardTotalItem keptScore={$totals.player.upperSubtotal} />
  </div>
  <div class="col-span-2 bg-black text-white font-nunito_bold text-center">
    Upper Subtotal
  </div>
  <div class="">
    <ScoreCardTotalItem keptScore={$totals.computer.upperSubtotal} />
  </div>

  <div class="">
    <ScoreCardTotalItem keptScore={$totals.player.upperBonus} />
  </div>
  <div class="col-span-2 bg-black text-white font-nunito_bold text-center">
    Upper Bonus >= 63
  </div>
  <div class="">
    <ScoreCardTotalItem keptScore={$totals.computer.upperBonus} />
  </div>

  <div class="">
    <ScoreCardTotalItem keptScore={$totals.player.upperTotal} />
  </div>
  <div class="col-span-2 bg-black text-white font-nunito_bold text-center">
    Upper Total
  </div>
  <div class="">
    <ScoreCardTotalItem keptScore={$totals.computer.upperTotal} />
  </div>

  <div class="">
    <ScoreCardItemPlayer
      category="threeOfAKind"
      rollScore={$state.rollScore.player.scorecard.threeOfAKind}
      keptScore={$state.gameScore.player.threeOfAKind}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Three of a Kind</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.threeOfAKind}
      keptScore={$state.gameScore.computer.threeOfAKind}
    />
  </div>

  <div class="">
    <ScoreCardItemPlayer
      category="fourOfAKind"
      rollScore={$state.rollScore.player.scorecard.fourOfAKind}
      keptScore={$state.gameScore.player.fourOfAKind}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Four of a Kind</div>
  <ScoreCardItemComputer
    rollScore={$state.rollScore.computer.scorecard.fourOfAKind}
    keptScore={$state.gameScore.computer.fourOfAKind}
  />

  <div class="">
    <ScoreCardItemPlayer
      category="fullHouse"
      rollScore={$state.rollScore.player.scorecard.fullHouse}
      keptScore={$state.gameScore.player.fullHouse}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Full House</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.fullHouse}
      keptScore={$state.gameScore.computer.fullHouse}
    />
  </div>

  <div class="">
    <ScoreCardItemPlayer
      category="smallStraight"
      rollScore={$state.rollScore.player.scorecard.smallStraight}
      keptScore={$state.gameScore.player.smallStraight}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Small Straght</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.smallStraight}
      keptScore={$state.gameScore.computer.smallStraight}
    />
  </div>

  <div class="">
    <ScoreCardItemPlayer
      category="largeStraight"
      rollScore={$state.rollScore.player.scorecard.largeStraight}
      keptScore={$state.gameScore.player.largeStraight}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Large Straight</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.largeStraight}
      keptScore={$state.gameScore.computer.largeStraight}
    />
  </div>

  <div class="">
    <ScoreCardItemPlayer
      category="chance"
      rollScore={$state.rollScore.player.scorecard.chance}
      keptScore={$state.gameScore.player.chance}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Chance</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.chance}
      keptScore={$state.gameScore.computer.chance}
    />
  </div>

  <div class="">
    <ScoreCardItemPlayer
      category="yahtzee"
      rollScore={$state.rollScore.player.scorecard.yahtzee}
      keptScore={$state.gameScore.player.yahtzee}
    />
  </div>
  <div class="col-span-2 bg-gray-200 text-center">Yahtzee</div>
  <div class="">
    <ScoreCardItemComputer
      rollScore={$state.rollScore.computer.scorecard.yahtzee}
      keptScore={$state.gameScore.computer.yahtzee}
    />
  </div>
  <div class="">
    <ScoreCardTotalItem keptScore={$totals.player.lowerTotal} />
  </div>
  <div class="col-span-2 bg-black text-white font-nunito_bold text-center">
    Lower Total
  </div>
  <div class="">
    <ScoreCardTotalItem keptScore={$totals.computer.lowerTotal} />
  </div>

  <div class="">
    <ScoreCardTotalItem keptScore={$totals.player.grandTotal} />
  </div>
  <div class="col-span-2 bg-black text-white font-nunito_bold text-center">
    Grand Total
  </div>
  <div class="">
    <ScoreCardTotalItem keptScore={$totals.computer.grandTotal} />
  </div>
</div>
