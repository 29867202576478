<script>
  import { state } from "../stores/state.js"
</script>

<div class="text-center font-nunito_light">
  <div class="{$state.playerRollCount === 0 ? '' : 'hidden'} text-xs mt-6">
    Click the PLAYER'S ROLL button to roll the dice.
  </div>
  <div
    class="{$state.playerRollCount === 1 || $state.playerRollCount === 2
      ? ''
      : 'hidden'} text-xs mt-6"
  >
    Click the dice to toggle the ones kept (green) and the ones discarded (red).
  </div>
  <div class="{$state.playerRollCount > 0 ? '' : 'hidden'} text-xs mt-6">
    Click any green box on the right to take your score for this roll.
  </div>
  <div
    class="{$state.playerRollCount === 1 && $state.canRoll
      ? ''
      : 'hidden'} text-xs mt-6"
  >
    Click the PLAYER'S SECOND ROLL button to roll the dice.
  </div>
  <div
    class="{$state.playerRollCount === 1 && !$state.canRoll
      ? ''
      : 'hidden'} text-xs mt-6"
  >
    Discard at least one dice to activate the PLAYER'S SECOND ROLL button.
  </div>
  <div
    class="{$state.playerRollCount === 2 && $state.canRoll
      ? ''
      : 'hidden'} text-xs mt-6"
  >
    Click the PLAYER'S THIRD ROLL button to roll the dice.
  </div>
  <div
    class="{$state.playerRollCount === 2 && !$state.canRoll
      ? ''
      : 'hidden'} text-xs mt-6"
  >
    Discard at least one dice to activate the PLAYER'S THIRD ROLL button.
  </div>
</div>
