export function initializeGameScore() {
    return {
      player: getInitialScore(),
      computer: getInitialScore()
    }
}
  
function getInitialScore () {
  return {
    ones: {
      score: 0,
      taken: false,
    },
    twos: {
      score: 0,
      taken: false,
    },
    threes: {
      score: 0,
      taken: false,
    },
    fours: {
      score: 0,
      taken: false,
    },
    fives: {
      score: 0,
      taken: false,
    },
    sixes: {
      score: 0,
      taken: false,
    },
    threeOfAKind: {
      score: 0,
      taken: false,
    },
    fourOfAKind: {
      score: 0,
      taken: false,
    },
    fullHouse: {
      score: 0,
      taken: false,
    },
    smallStraight: {
      score: 0,
      taken: false,
    },
    largeStraight: {
      score: 0,
      taken: false,
    },
    chance: {
      score: 0,
      taken: false,
    },
    yahtzee: {
      score: 0,
      taken: false,
    },
    bonusYahtzee: {
      score: 0,
      taken: false,
    },
    lastTaken: '',
    lastTakenPoints: 0,
  }
}