import { initializeGameScore } from '../subroutines/initializeGameScore'
import { initializeRollScore } from '../subroutines/initializeRollScore'

export function getInitialState() {
    return {
      canRoll: true,
      canTakeScore: false,
      playerRollCount: 0,
      dice: {
        player: getInitialDice(),
        computer1: getInitialDice(),
        computer2: getInitialDice(),
        computer3: getInitialDice()
    },
      player: 'player',
      roundsRemaining: 13,
      rollScore: initializeRollScore(),
      gameScore: initializeGameScore()
    }
}
  
function getInitialDice() {
    let initialDice = []

    for (let index = 0; index < 5; index++) {
      let die = {}
      die.value = 1
      die.kept = false
      die.order = index
      initialDice.push(die)
    }

    return initialDice
  }