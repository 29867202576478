<script>
  import { state } from "../stores/state.js"
  export let rollScore
  export let keptScore
  export let category
</script>

{#if keptScore.taken}
  <div class="w-full text-center bg-red-300">
    {keptScore.score}
  </div>
{:else if $state.canTakeScore}
  <div
    class="w-full text-center bg-emerald-300 cursor-pointer"
    on:click={state.takeScore(category, rollScore)}
  >
    {rollScore}
  </div>
{:else}
  <div class="w-full text-center bg-emerald-300">
    {rollScore}
  </div>
{/if}
