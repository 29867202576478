<script>
  import { state } from "../stores/state.js"
  import Dice from "../components/Dice.svelte"
  import RollButton from "../components/RollButton.svelte"
  import Help from "../components/Help.svelte"
</script>

<div class={$state.roundsRemaining === 0 ? "hidden" : ""}>
  <div class="text-gray-500 text-center text-4xl font-roboto_bold mb-6">
    Player
  </div>
  <Dice owner="player" />
  <RollButton />
  <Help />
</div>
