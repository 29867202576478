export function determineDiceRoll(dice) {

    if (dice[0].kept === false) {
      dice[0].value = Math.floor(Math.random() * 6) + 1
    }
    if (dice[1].kept === false) {
      dice[1].value = Math.floor(Math.random() * 6) + 1
    }
    if (dice[2].kept === false) {
      dice[2].value = Math.floor(Math.random() * 6) + 1
    }
    if (dice[3].kept === false) {
      dice[3].value = Math.floor(Math.random() * 6) + 1
    }
    if (dice[4].kept === false) {
      dice[4].value = Math.floor(Math.random() * 6) + 1
    }

    return dice
  }