<script>
  import Computer from "./components/Computer.svelte"
  import Footer from "./components/Footer.svelte"
  import Header from "./components/Header.svelte"
  import NewGame from "./components/NewGame.svelte"
  import Player from "./components/Player.svelte"
  import ScoreCard from "./components/ScoreCard.svelte"
</script>

<svelte:head>
  <title>Yahtzee</title>
  <meta
    name="description"
    content="A single player Yahtzee game built using Svelte."
  />
  <html lang="en" />
</svelte:head>

<Header />
<main class="flex w-full font-nunito_regular justify-center bg-gray-50 pb-6">
  <div class="w-2/3 grid grid-cols-2 gap-6">
    <div class="flex flex-col bg-gray-100 border border-gray-200 p-6">
      <div class="flex-1">
        <Player />
        <Computer />
        <NewGame />
      </div>
    </div>
    <div class="flex flex-col bg-gray-100 border border-gray-200 p-6">
      <ScoreCard />
    </div>
  </div>
</main>
<Footer />

<style lang="postcss" global>
  @tailwind base;

  @font-face {
    font-family: "roboto_bold";
    src: url("fonts/RobotoSlab-Bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_black";
    src: url("fonts/NunitoSans-Black.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_black_italic";
    src: url("fonts/NunitoSans-BlackItalic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_bold";
    src: url("fonts/NunitoSans-Bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_bold_italic";
    src: url("fonts/NunitoSans-BoldItalic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_extrabold";
    src: url("fonts/NunitoSans-ExtraBold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_extrabold_italic";
    src: url("fonts/NunitoSans-ExtraBoldItalic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_extralight";
    src: url("fonts/NunitoSans-ExtraLight.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_extralight_italic";
    src: url("fonts/NunitoSans-ExtraLightItalic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_italic";
    src: url("fonts/NunitoSans-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_light";
    src: url("fonts/NunitoSans-Light.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_light_italic";
    src: url("fonts/NunitoSans-LightItalic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_regular";
    src: url("fonts/NunitoSans-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_semibold";
    src: url("fonts/NunitoSans-SemiBold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "nunito_semibold_italic";
    src: url("fonts/NunitoSans-SemiBoldItalic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @tailwind components;

  body {
    padding: 0;
  }

  @tailwind utilities;
</style>
