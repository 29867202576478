<script>
  import { state } from "../stores/state.js"
  import Dice from "../components/Dice.svelte"
</script>

{#if $state.player === "computer"}
  <div class="text-gray-500 text-center text-4xl font-roboto_bold my-6">
    Computer
  </div>
  <div class="mb-6"><Dice owner="computer3" /></div>
  <div
    class="text-center mb-6 {$state.gameScore.computer.lastTaken === ''
      ? 'hidden'
      : ''}"
  >
    Computer takes {$state.gameScore.computer.lastTaken} - {$state.gameScore
      .computer.lastTakenPoints}
    {$state.gameScore.computer.lastTakenPoints === 1 ? "point" : "points"}
  </div>
{/if}
