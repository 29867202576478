<script></script>

<div class="bg-gray-50 text-gray-800 text-center">
  <div class=" text-5xl font-roboto_bold pt-4">Yahtzee</div>
  <div class="pb-4">
    <a
      href="https://www.ultraboardgames.com/yahtzee/game-rules.php"
      class="text-xs text-gray-400 visited:text-gray-400 hover:text-gray-800"
      target="_blank"
      rel="noopener noreferrer"
    >
      How to Play
    </a>
  </div>
</div>
