import { writable } from 'svelte/store'
import { determineDiceResults } from '../subroutines/determineDiceResults'
import { determineDiceRoll } from '../subroutines/determineDiceRoll'
import { determineKeptComputerDice } from '../subroutines/determineKeptComputerDice'
import { determineRollScore } from '../subroutines/determineRollScore'
import { getInitialState } from '../subroutines/getInitialState'
import { initializeRollScore } from '../subroutines/initializeRollScore'
import { takeComputerScore } from '../subroutines/takeComputerScore'

function createState() {

  const { subscribe, set, update } = writable(getInitialState())

  return {
    subscribe,

    // rollDice 

    rollDice: () => update(nextState => {
      if (!nextState.canRoll) return nextState

      // Roll the dice. Assign each die a value. Repeat 10 times.

      let counter = 9

      const interval = setInterval(() => {

        nextState.dice.player = determineDiceRoll(nextState.dice.player)

        nextState.rollScore = initializeRollScore()

        nextState.rollScore.player = determineDiceResults(nextState.dice.player, nextState.rollScore.player)

        nextState.rollScore.player = determineRollScore(nextState.rollScore.player)

        // finish
        counter--
        set(nextState)

        // Finish off the roll.

        if (counter < 0) {
          for (let index = 0; index < 5; index++) {
            nextState.dice.player[index].kept = true
          }
          nextState.playerRollCount++
          nextState.canRoll = false
          nextState.canTakeScore = true
          nextState.player = 'player'
          clearInterval(interval)
        }
      }, 60)

      return nextState
    }),

    // toggleDiceKept 
    
    toggleDiceKept: (order) => update(nextState => {
      if (nextState.playerRollCount === 0 || nextState.playerRollCount > 2) return nextState
      nextState.dice.player[order].kept = !nextState.dice.player[order].kept
      nextState.canRoll = false
      for (let index = 0; index < 5; index++) {
        if (!nextState.dice.player[index].kept) {
          nextState.canRoll = true
        }
      }
      return nextState
    }),

    // takeScore

    takeScore: (category, score) => update(nextState => {
      if (!nextState.canTakeScore) return nextState
      nextState.gameScore.player[category].taken = true
      nextState.gameScore.player[category].score = score
      nextState.playerRollCount = 0
      nextState.canRoll = true
      nextState.canTakeScore = false
      nextState.player = 'computer'
      nextState.roundsRemaining--
      for (let index = 0; index < 5; index++) {
        nextState.dice.player[index].kept = false
      }

      // Computer's turn 

      for (let index = 0; index < 5; index++) {
        nextState.dice.computer1[index].kept = false
      }

      let results
      let rollAgain

      nextState.dice.computer1 = determineDiceRoll(nextState.dice.computer1)
      nextState.rollScore.computer = determineDiceResults(nextState.dice.computer1, nextState.rollScore.computer)
      nextState.rollScore.computer = determineRollScore(nextState.rollScore.computer)
      // console.log('COMPUTER TURN - 1')
      results = determineKeptComputerDice(nextState.dice.computer1, nextState.rollScore.computer, nextState.gameScore.computer, nextState.roundsRemaining)
      nextState.dice.computer1 = results.updatedDice
      rollAgain = results.rollAgain

      if (rollAgain) {
        nextState.rollScore = initializeRollScore()
        let dice2 = { ...nextState.dice.computer1 }
        nextState.dice.computer2 = determineDiceRoll(dice2)
        nextState.rollScore.computer = determineDiceResults(nextState.dice.computer2, nextState.rollScore.computer)
        nextState.rollScore.computer = determineRollScore(nextState.rollScore.computer)
        // console.log('COMPUTER TURN - 2')
        results = determineKeptComputerDice(nextState.dice.computer2, nextState.rollScore.computer, nextState.gameScore.computer, nextState.roundsRemaining)
        nextState.dice.computer2 = results.updatedDice
        rollAgain = results.rollAgain
      }

      if (rollAgain) {
        nextState.rollScore = initializeRollScore()
        let dice3 = { ...nextState.dice.computer2 }
        nextState.dice.computer3 = determineDiceRoll(dice3)
        // console.log('COMPUTER TURN - 3')
        nextState.rollScore.computer = determineDiceResults(nextState.dice.computer3, nextState.rollScore.computer)
        nextState.rollScore.computer = determineRollScore(nextState.rollScore.computer)
      }

      nextState.gameScore.computer = takeComputerScore(nextState.rollScore.computer, nextState.gameScore.computer)

      for (let index = 0; index < 5; index++) {
        nextState.dice.computer3[index].kept = true
      }

      return nextState
    }),

    // startNewGame 

    startNewGame: () => set(getInitialState()),

  }
  
}

export const state = createState()
