<script>
  import { state } from "../stores/state.js"
  const buttonLabel = [
    "Player's Roll",
    "Player's Second Roll",
    "Player's Third Roll",
  ]
</script>

<div
  class="flex mt-6 justify-center {$state.playerRollCount > 2 ? 'hidden' : ''}"
>
  <button
    on:click={state.rollDice}
    class="w-48  p-2 border-none rounded mr-4 text-white font-nunito_bold 
        {!$state.canRoll
      ? 'bg-emerald-100 cursor-default'
      : 'bg-emerald-500 hover:bg-emerald-900 cursor-pointer'}"
  >
    {buttonLabel[$state.playerRollCount]}
  </button>
</div>
