export function determineKeptComputerDice(dice, rollScore, gameScore, roundsRemaining) {
    let updatedDice = []

    switch (rollScore.major) {
      case 1:
        if (gameScore.ones.taken) {
          rollScore.majorTaken = true
        }
        break
      
      case 2:
        if (gameScore.twos.taken) {
          rollScore.majorTaken = true
        }
        break
      
      case 3:
        if (gameScore.threes.taken) {
          rollScore.majorTaken = true
        }
        break
      
      case 4:
        if (gameScore.fours.taken) {
          rollScore.majorTaken = true
        }
        break
      
      case 5:
        if (gameScore.fives.taken) {
          rollScore.majorTaken = true
        }
        break
      
      case 6:
        if (gameScore.sixes.taken) {
          rollScore.majorTaken = true
        }
        break
    
      default:
        break
    }

    switch (rollScore.minor) {
      case 1:
        if (gameScore.ones.taken) {
          rollScore.minorTaken = true
        }
        break
      
      case 2:
        if (gameScore.twos.taken) {
          rollScore.minorTaken = true
        }
        break
      
      case 3:
        if (gameScore.threes.taken) {
          rollScore.minorTaken = true
        }
        break
      
      case 4:
        if (gameScore.fours.taken) {
          rollScore.minorTaken = true
        }
        break
      
      case 5:
        if (gameScore.fives.taken) {
          rollScore.minorTaken = true
        }
        break
      
      case 6:
        if (gameScore.sixes.taken) {
          rollScore.minorTaken = true
        }
        break
    
      default:
        break
    }

    
    // console.log(rollScore)
    // console.log(gameScore)
    // console.log(roundsRemaining)

    // hard to get strategy - keep all dice

    if (rollScore.yahtzee && !gameScore.yahtzee.taken) {
      for (let index = 0; index < 5; index++) {
        const die = dice[index]
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: false }
    }
    else if (rollScore.largeStraight && !gameScore.largeStraight.taken) {
      for (let index = 0; index < 5; index++) {
        const die = dice[index]
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: false }
    }
    else if (rollScore.smallStraight && gameScore.largeStraight.taken && !gameScore.smallStraight.taken) {
      for (let index = 0; index < 5; index++) {
        const die = dice[index]
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: false }
    }
    else if (rollScore.fullHouse && !gameScore.fullHouse.taken) {
      for (let index = 0; index < 5; index++) {
        const die = dice[index]
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: false }
    }

    // full house strategy

    if (rollScore.twoPair && !gameScore.fullHouse.taken) {
      // console.log('FULL HOUSE STRATEGY')
      for (let index = 0; index < 5; index++) {
        let die = dice[index]
        let kept = false
        if (die.value === rollScore.major) kept = true
        if (die.value === rollScore.minor) kept = true
        die.kept = kept
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: true }
    }

    // outside straight strategy

    if (rollScore.possibleOutsideSmallStraight && !gameScore.smallStraight.taken) {
      // console.log('SMALL STRAIGHT STRATEGY')
      for (let index = 0; index < 5; index++) {
        let die = dice[index]
        let kept = false
        if (rollScore.straight.includes(die.value)) {
          kept = true
          rollScore.straight = arrayRemove(rollScore.straight, die.value)
        }
        die.kept = kept
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: true }
    }

    if (rollScore.possibleOutsideLargeStraight && !gameScore.largeStraight.taken) {
      // console.log('LARGE STRAIGHT STRATEGY')
      for (let index = 0; index < 5; index++) {
        let die = dice[index]
        let kept = false
        if (rollScore.straight.includes(die.value)) {
          kept = true
          rollScore.straight = arrayRemove(rollScore.straight, die.value)
        }
        die.kept = kept
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: true }
    }

      if (rollScore.smallStraight && !gameScore.largeStraight.taken) {
      // console.log('LARGE STRAIGHT STRATEGY')
      for (let index = 0; index < 5; index++) {
        let die = dice[index]
        let kept = false
        if (rollScore.straight.includes(die.value)) {
          kept = true
          rollScore.straight = arrayRemove(rollScore.straight, die.value)
        }
        die.kept = kept
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: true }
    }

    // yahtzee, four of a kind, three of a kind strategy

    if (rollScore.major && !rollScore.majorTaken) {
      if (rollScore.major < 3 && roundsRemaining > 8) {
        // go on to the next strategy
      }
      else {
        // console.log('YAHTZEE STRATEGY DUPLICATE DICE')
        for (let index = 0; index < 5; index++) {
          const die = dice[index]
          die.kept = die.value === rollScore.major ? true : false
          // console.log(die)
          updatedDice.push(die)
        }
        return { updatedDice: updatedDice, rollAgain: true }
      }
    }

    if (rollScore.major && rollScore.major > 3 && rollScore.threeOfAKind && (!gameScore.threeOfAKind.taken || !gameScore.fourOfAKind.taken)) {
      // console.log('YAHTZEE STRATEGY - 3 OF A KIND')
      for (let index = 0; index < 5; index++) {
        const die = dice[index]
        die.kept = die.value === rollScore.major ? true : false
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: true }
    }

    if (rollScore.major && rollScore.major > 2 && rollScore.fourOfAKind && (!gameScore.fourOfAKind.taken || !gameScore.yahtzee.taken)) {
      // console.log('YAHTZEE STRATEGY - 4 OF A KIND')
      for (let index = 0; index < 5; index++) {
        const die = dice[index]
        die.kept = die.value === rollScore.major ? true : false
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: true }
    }

    if (rollScore.minor && !rollScore.minorTaken) {
      if (rollScore.minor < 3 && roundsRemaining > 8) {
        // go on to the next strategy
      }
      else {
        // console.log('YAHTZEE STRATEGY DUPLICATE DICE - MINOR')
        for (let index = 0; index < 5; index++) {
          const die = dice[index]
          die.kept = die.value === rollScore.minor ? true : false
          // console.log(die)
          updatedDice.push(die)
        }
        return { updatedDice: updatedDice, rollAgain: true }
      }
    }

    if (rollScore.minor && rollScore.minor > 3 && rollScore.threeOfAKind && (!gameScore.threeOfAKind.taken || !gameScore.fourOfAKind.taken)) {
      // console.log('YAHTZEE STRATEGY - 3 OF A KIND - MINOR')
      for (let index = 0; index < 5; index++) {
        const die = dice[index]
        die.kept = die.value === rollScore.minor ? true : false
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: true }
    }

    if (rollScore.minor && rollScore.minor > 2 && rollScore.fourOfAKind && (!gameScore.fourOfAKind.taken || !gameScore.yahtzee.taken)) {
      // console.log('YAHTZEE STRATEGY - 4 OF A KIND - MINOR')
      for (let index = 0; index < 5; index++) {
        const die = dice[index]
        die.kept = die.value === rollScore.minor ? true : false
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: true }
    }

    // untaken dice strategy
    if (roundsRemaining < 9) {
      // console.log('UNTAKEN DICE STRATEGY')
      let notTaken = []
      if (!gameScore.ones.taken) notTaken.push(1)
      if (!gameScore.twos.taken) notTaken.push(2)
      if (!gameScore.threes.taken) notTaken.push(3)
      if (!gameScore.fours.taken) notTaken.push(4)
      if (!gameScore.fives.taken) notTaken.push(5)
      if (!gameScore.sixes.taken) notTaken.push(6)
      for (let index = 0; index < 5; index++) {
        let die = dice[index]
        let kept = false
        if (notTaken.includes(die.value)) {
          kept = true
        }
        die.kept = kept
        // console.log(die)
        updatedDice.push(die)
      }
      return { updatedDice: updatedDice, rollAgain: true }
    }

    // default strategy

    // console.log('DEFAULT STRATEGY')
    for (let index = 0; index < 5; index++) {
      const die = dice[index]
      die.kept = die.value > 4 ? true : false
      // console.log(die)
      updatedDice.push(die)
    }
    return { updatedDice: updatedDice, rollAgain: true }
  }

    // arrayRemove

  function arrayRemove(arr, value) { 

    return arr.filter(function(ele){ 
        return ele != value; 
    });
  }
