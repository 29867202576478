import { derived } from 'svelte/store'
import { state } from '../stores/state'

export const totals = derived(state, $state => {
  const playerUpperSubtotal = $state.gameScore.player.ones.score + $state.gameScore.player.twos.score + $state.gameScore.player.threes.score + $state.gameScore.player.fours.score + $state.gameScore.player.fives.score + $state.gameScore.player.sixes.score

  const playerUpperBonus = playerUpperSubtotal > 62 ? 35 : 0

  const playerUpperTotal = playerUpperSubtotal + playerUpperBonus

  const playerLowerTotal = $state.gameScore.player.threeOfAKind.score + $state.gameScore.player.fourOfAKind.score + $state.gameScore.player.fullHouse.score + $state.gameScore.player.smallStraight.score + $state.gameScore.player.largeStraight.score + $state.gameScore.player.yahtzee.score + $state.gameScore.player.chance.score

  const playerGrandTotal = playerUpperTotal + playerLowerTotal

  const computerUpperSubtotal = $state.gameScore.computer.ones.score + $state.gameScore.computer.twos.score + $state.gameScore.computer.threes.score + $state.gameScore.computer.fours.score + $state.gameScore.computer.fives.score + $state.gameScore.computer.sixes.score

  const computerUpperBonus = computerUpperSubtotal > 62 ? 35 : 0

  const computerUpperTotal = computerUpperSubtotal + computerUpperBonus

  const computerLowerTotal = $state.gameScore.computer.threeOfAKind.score + $state.gameScore.computer.fourOfAKind.score + $state.gameScore.computer.fullHouse.score + $state.gameScore.computer.smallStraight.score + $state.gameScore.computer.largeStraight.score + $state.gameScore.computer.yahtzee.score + $state.gameScore.computer.chance.score

  const computerGrandTotal = computerUpperTotal + computerLowerTotal

  let winner
  if (playerGrandTotal > computerGrandTotal) {
    winner = 'Player Wins'
  }
  else if (playerGrandTotal < computerGrandTotal) {
    winner = 'Computer Wins'
  }
  else {
    winner = 'Tie Game'
  }

  return {
    player: {
      upperSubtotal: playerUpperSubtotal,
      upperBonus: playerUpperBonus,
      upperTotal: playerUpperTotal,
      lowerTotal: playerLowerTotal,
      grandTotal: playerGrandTotal
    },
    computer: {
      upperSubtotal: computerUpperSubtotal,
      upperBonus: computerUpperBonus,
      upperTotal: computerUpperTotal,
      lowerTotal: computerLowerTotal,
      grandTotal: computerGrandTotal
    },
    winner: winner
  }

})

