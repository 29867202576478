<script>
  import Icon from "@iconify/svelte"
  import { state } from "../stores/state.js"
  export let die
  export let owner
</script>

<div class={owner === "player" ? "" : "hidden"}>
  <div
    on:click={state.toggleDiceKept(die.order)}
    class="mx-1 {$state.playerRollCount > 0 && $state.playerRollCount < 3
      ? 'cursor-pointer'
      : 'cursor-default'}"
  >
    {#if die.value === 1}
      <Icon
        icon="bi:dice-1"
        class="{die.kept ? 'text-green-600' : 'text-red-200'} 
       h-12 w-12"
      />
    {:else if die.value === 2}
      <Icon
        icon="bi:dice-2"
        class="{die.kept ? 'text-green-600' : 'text-red-200'} h-12 w-12"
      />
    {:else if die.value === 3}
      <Icon
        icon="bi:dice-3"
        class="{die.kept ? 'text-green-600' : 'text-red-200'} h-12 w-12"
      />
    {:else if die.value === 4}
      <Icon
        icon="bi:dice-4"
        class="{die.kept ? 'text-green-600' : 'text-red-200'} h-12 w-12"
      />
    {:else if die.value === 5}
      <Icon
        icon="bi:dice-5"
        class="{die.kept ? 'text-green-600' : 'text-red-200'} h-12 w-12"
      />
    {:else if die.value === 6}
      <Icon
        icon="bi:dice-6"
        class="{die.kept ? 'text-green-600' : 'text-red-200'} h-12 w-12"
      />
    {:else}
      <span />
    {/if}
  </div>
</div>

<div class={owner === "computer3" ? "" : "hidden"}>
  <div class="mx-1">
    {#if die.value === 1}
      <Icon
        icon="bi:dice-1"
        class="text-gray-700 
       h-12 w-12"
      />
    {:else if die.value === 2}
      <Icon icon="bi:dice-2" class="text-gray-700 h-12 w-12" />
    {:else if die.value === 3}
      <Icon icon="bi:dice-3" class="text-gray-700 h-12 w-12" />
    {:else if die.value === 4}
      <Icon icon="bi:dice-4" class="text-gray-700 h-12 w-12" />
    {:else if die.value === 5}
      <Icon icon="bi:dice-5" class="text-gray-700 h-12 w-12" />
    {:else if die.value === 6}
      <Icon icon="bi:dice-6" class="text-gray-700 h-12 w-12" />
    {:else}
      <span />
    {/if}
  </div>
</div>
