export function initializeRollScore() {
  return {
    player: getRollScore(),
    computer: getRollScore()
  }
}
  
function getRollScore() {
  return {
    results: [0, 0, 0, 0, 0, 0],
    chance: 0,
    pairs: 0,
    threeOfAKind: 0,
    fourOfAKind: 0,
    yahtzee: 0,
    onePair: 0,
    twoPair: 0,
    fullHouse: 0,
    smallStraight: 0,
    largeStraight: 0,
    straight: [],
    possibleOutsideSmallStraight: false,
    possibleOutsideLargeStraight: false,
    major: 0,
    majorTaken: false,
    minor: 0,
    minorTaken: false,
    scorecard: {
      ones: 0,
      twos: 0,
      threes: 0,
      fours: 0,
      fives: 0,
      sixes: 0,
      threeOfAKind: 0,
      fourOfAKind: 0,
      fullHouse: 0,
      smallStraight: 0,
      largeStraight: 0,
      yahtzee: 0,
      chance: 0,
      yahtzeeBonus: 0
    }
  }
}