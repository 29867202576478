export function takeComputerScore(rollScore, gameScore) {
    // console.log('TAKE COMPUTER SCORE')
    // console.log(rollScore)
    // console.log(gameScore)
    if (rollScore.scorecard.yahtzee === 50 && !gameScore.yahtzee.taken) {
      gameScore.yahtzee.score = rollScore.scorecard.yahtzee
      gameScore.yahtzee.taken = true
      gameScore.lastTaken = 'Yahtzee'
      gameScore.lastTakenPoints = rollScore.scorecard.yahtzee
    }
    else if (rollScore.scorecard.largeStraight === 40 && !gameScore.largeStraight.taken) {
      gameScore.largeStraight.score = rollScore.scorecard.largeStraight
      gameScore.largeStraight.taken = true
      gameScore.lastTaken = 'Large Straight'
      gameScore.lastTakenPoints = rollScore.scorecard.largeStraight
    }
    else if (rollScore.scorecard.smallStraight === 30 && !gameScore.smallStraight.taken) {
      gameScore.smallStraight.score = rollScore.scorecard.smallStraight
      gameScore.smallStraight.taken = true
      gameScore.lastTaken = 'Small Straight'
      gameScore.lastTakenPoints = rollScore.scorecard.smallStraight
    }
    else if (rollScore.scorecard.fullHouse === 25 && !gameScore.fullHouse.taken) {
      gameScore.fullHouse.score = rollScore.scorecard.fullHouse
      gameScore.fullHouse.taken = true
      gameScore.lastTaken = 'Full House'
      gameScore.lastTakenPoints = rollScore.scorecard.fullHouse
    }
    else if (rollScore.scorecard.fourOfAKind > 16 && !gameScore.fourOfAKind.taken) {
      gameScore.fourOfAKind.score = rollScore.scorecard.fourOfAKind
      gameScore.fourOfAKind.taken = true
      gameScore.lastTaken = 'Four of a Kind'
      gameScore.lastTakenPoints = rollScore.scorecard.fourOfAKind
    }
    else if (rollScore.scorecard.sixes > 12 && !gameScore.sixes.taken) {
      gameScore.sixes.score = rollScore.scorecard.sixes
      gameScore.sixes.taken = true
      gameScore.lastTaken = 'Sixes'
      gameScore.lastTakenPoints = rollScore.scorecard.sixes
    }
    else if (rollScore.scorecard.fives > 10 && !gameScore.fives.taken) {
      gameScore.fives.score = rollScore.scorecard.fives
      gameScore.fives.taken = true
      gameScore.lastTaken = 'Fives'
      gameScore.lastTakenPoints = rollScore.scorecard.fives
    }
    else if (rollScore.scorecard.fours > 8 && !gameScore.fours.taken) {
      gameScore.fours.score = rollScore.scorecard.fours
      gameScore.fours.taken = true
      gameScore.lastTaken = 'Fours'
      gameScore.lastTakenPoints = rollScore.scorecard.fours
    }
    else if (rollScore.scorecard.threes > 6 && !gameScore.threes.taken) {
      gameScore.threes.score = rollScore.scorecard.threes
      gameScore.threes.taken = true
      gameScore.lastTaken = 'Threes'
      gameScore.lastTakenPoints = rollScore.scorecard.threes
    }
    else if (rollScore.scorecard.fourOfAKind && !gameScore.fourOfAKind.taken) {
      gameScore.fourOfAKind.score = rollScore.scorecard.fourOfAKind
      gameScore.fourOfAKind.taken = true
      gameScore.lastTaken = 'Four of a Kind'
      gameScore.lastTakenPoints = rollScore.scorecard.fourOfAKind
    }
    else if (rollScore.scorecard.threeOfAKind && !gameScore.threeOfAKind.taken) {
      gameScore.threeOfAKind.score = rollScore.scorecard.threeOfAKind
      gameScore.threeOfAKind.taken = true
      gameScore.lastTaken = 'Three of a Kind'
      gameScore.lastTakenPoints = rollScore.scorecard.threeOfAKind
    }
    else if (rollScore.scorecard.threes > 3 && !gameScore.threes.taken) {
      gameScore.threes.score = rollScore.scorecard.threes
      gameScore.threes.taken = true
      gameScore.lastTaken = 'Threes'
      gameScore.lastTakenPoints = rollScore.scorecard.threes
    }
    else if (rollScore.scorecard.fours > 4 && !gameScore.fours.taken) {
      gameScore.fours.score = rollScore.scorecard.fours
      gameScore.fours.taken = true
      gameScore.lastTaken = 'Fours'
      gameScore.lastTakenPoints = rollScore.scorecard.fours
    }
    else if (rollScore.scorecard.fives > 5 && !gameScore.fives.taken) {
      gameScore.fives.score = rollScore.scorecard.fives
      gameScore.fives.taken = true
      gameScore.lastTaken = 'Fives'
      gameScore.lastTakenPoints = rollScore.scorecard.fives
    }
    else if (rollScore.scorecard.sixes > 6 && !gameScore.sixes.taken) {
      gameScore.sixes.score = rollScore.scorecard.sixes
      gameScore.sixes.taken = true
      gameScore.lastTaken = 'Sixes'
      gameScore.lastTakenPoints = rollScore.scorecard.sixes
    }
    else if (!gameScore.ones.taken) {
      gameScore.ones.score = rollScore.scorecard.ones
      gameScore.ones.taken = true
      gameScore.lastTaken = 'Ones'
      gameScore.lastTakenPoints = rollScore.scorecard.ones
    }
    else if (!gameScore.twos.taken) {
      gameScore.twos.score = rollScore.scorecard.twos
      gameScore.twos.taken = true
      gameScore.lastTaken = 'Twos'
      gameScore.lastTakenPoints = rollScore.scorecard.twos
    }
    else if (rollScore.scorecard.chance > 23 && !gameScore.chance.taken) {
      gameScore.chance.score = rollScore.scorecard.chance
      gameScore.chance.taken = true
      gameScore.lastTaken = 'Chance'
      gameScore.lastTakenPoints = rollScore.scorecard.chance
    }
    else if (!gameScore.yahtzee.taken) {
      gameScore.yahtzee.score = rollScore.scorecard.yahtzee
      gameScore.yahtzee.taken = true
      gameScore.lastTaken = 'Yahtzee'
      gameScore.lastTakenPoints = rollScore.scorecard.yahtzee
    }
    else if (!gameScore.threes.taken) {
      gameScore.threes.score = rollScore.scorecard.threes
      gameScore.threes.taken = true
      gameScore.lastTaken = 'Threes'
      gameScore.lastTakenPoints = rollScore.scorecard.threes
    }
    else if (!gameScore.fourOfAKind.taken) {
      gameScore.fourOfAKind.score = rollScore.scorecard.fourOfAKind
      gameScore.fourOfAKind.taken = true
      gameScore.lastTaken = 'Four of a Kind'
      gameScore.lastTakenPoints = rollScore.scorecard.fourOfAKind
    }
    else if (!gameScore.largeStraight.taken) {
      gameScore.largeStraight.score = rollScore.scorecard.largeStraight
      gameScore.largeStraight.taken = true
      gameScore.lastTaken = 'Large Straight'
      gameScore.lastTakenPoints = rollScore.scorecard.largeStraight
    }
    else if (!gameScore.chance.taken) {
      gameScore.chance.score = rollScore.scorecard.chance
      gameScore.chance.taken = true
      gameScore.lastTaken = 'Chance'
      gameScore.lastTakenPoints = rollScore.scorecard.chance
    }
    else if (!gameScore.smallStraight.taken) {
      gameScore.smallStraight.score = rollScore.scorecard.smallStraight
      gameScore.smallStraight.taken = true
      gameScore.lastTaken = 'Small Straight'
      gameScore.lastTakenPoints = rollScore.scorecard.smallStraight
    }
    else if (!gameScore.fullHouse.taken) {
      gameScore.fullHouse.score = rollScore.scorecard.fullHouse
      gameScore.fullHouse.taken = true
      gameScore.lastTaken = 'Full House'
      gameScore.lastTakenPoints = rollScore.scorecard.fullHouse
    }
    else if (!gameScore.fours.taken) {
      gameScore.fours.score = rollScore.scorecard.fours
      gameScore.fours.taken = true
      gameScore.lastTaken = 'Fours'
      gameScore.lastTakenPoints = rollScore.scorecard.fours
    }
    else if (!gameScore.threeOfAKind.taken) {
      gameScore.threeOfAKind.score = rollScore.scorecard.threeOfAKind
      gameScore.threeOfAKind.taken = true
      gameScore.lastTaken = 'Three of a Kind'
      gameScore.lastTakenPoints = rollScore.scorecard.threeOfAKind
    }
    else if (!gameScore.fives.taken) {
      gameScore.fives.score = rollScore.scorecard.fives
      gameScore.fives.taken = true
      gameScore.lastTaken = 'Fives'
      gameScore.lastTakenPoints = rollScore.scorecard.fives
    }
    else if (!gameScore.sixes.taken) {
      gameScore.sixes.score = rollScore.scorecard.sixes
      gameScore.sixes.taken = true
      gameScore.lastTaken = 'Sixes'
      gameScore.lastTakenPoints = rollScore.scorecard.sixes
    }
    
    return gameScore
}
  
