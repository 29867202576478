<script>
  import { state } from "../stores/state.js"
  export let rollScore
  export let keptScore
</script>

{#if keptScore.taken}
  <div class="w-full text-center bg-gray-500 text-white">
    {keptScore.score}
  </div>
{:else}
  <div class="w-full text-center bg-gray-200">
    {rollScore}
  </div>
{/if}
