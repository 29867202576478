export function determineRollScore(rollScore) {
    for (let index = 0; index < 6; index++) {
        const result = rollScore.results[index]
        switch (result) {
          case 2:
            rollScore.pairs++
            break
          case 3:
            rollScore.threeOfAKind++
            rollScore.major = index + 1
            break
          case 4:
            rollScore.threeOfAKind++
            rollScore.fourOfAKind++
            rollScore.major = index + 1
            break
          case 5:
            rollScore.threeOfAKind++
            rollScore.fourOfAKind++
            rollScore.yahtzee++
            rollScore.major = index + 1
            break
          default:
            break
        }
      }

      if (rollScore.pairs === 1) {
        rollScore.onePair = 1
      }
    
      if (rollScore.pairs === 2) {
        rollScore.twoPair = 1
        let firstPairFound = false
        for (let index = 0; index < 6; index++) {
          if (rollScore.results[index] === 2) {
            if (!firstPairFound) {
              rollScore.minor = index + 1
              firstPairFound = true
            }
            else {
              rollScore.major = index + 1
            }
          }
        }
      }
    
      if ((rollScore.pairs === 1) && (rollScore.threeOfAKind === 1)) {
        rollScore.fullHouse = 1
      }
    
        
      if ((rollScore.results[1] > 0) && (rollScore.results[2] > 0) && (rollScore.results[3] > 0)) {
        rollScore.possibleOutsideSmallStraight = true
        rollScore.straight = [2, 3, 4]
      }
    
      if ((rollScore.results[2] > 0) && (rollScore.results[3] > 0) && (rollScore.results[4] > 0)) {
        rollScore.possibleOutsideSmallStraight = true
        rollScore.straight = [3, 4, 5]
      }
    
      if ((rollScore.results[0] > 0) && (rollScore.results[1] > 0) && (rollScore.results[2] > 0) && (rollScore.results[3] > 0)) {
        rollScore.smallStraight = 1
        rollScore.possibleOutsideSmallStraight = false
        rollScore.straight = [1, 2, 3, 4]
      }
    
      if ((rollScore.results[1] > 0) && (rollScore.results[2] > 0) && (rollScore.results[3] > 0) && (rollScore.results[4] > 0)) {
        rollScore.smallStraight = 1
        rollScore.possibleOutsideSmallStraight = false
        rollScore.straight = [2, 3, 4, 5]
        rollScore.possibleOutsideLargeStraight = true
      }
    
      if ((rollScore.results[2] > 0) && (rollScore.results[3] > 0) && (rollScore.results[4] > 0) && (rollScore.results[5] > 0)) {
        rollScore.smallStraight = 1
        rollScore.possibleOutsideSmallStraight = false
        rollScore.straight = [3, 4, 5, 6]
      }
    
      if ((rollScore.results[0] > 0) && (rollScore.results[1] > 0) && (rollScore.results[2] > 0) && (rollScore.results[3] > 0) && (rollScore.results[4] > 0)) {
        rollScore.largeStraight = 1
        rollScore.straight = [1, 2, 3, 4, 5]
      }
    
      if ((rollScore.results[1] > 0) && (rollScore.results[2] > 0) && (rollScore.results[3] > 0) && (rollScore.results[4] > 0) && (rollScore.results[5] > 0)) {
        rollScore.largeStraight = 1
        rollScore.straight = [2, 3, 4, 5, 6]
      }
    
      if (rollScore.pairs === 2) {
        let firstPairFound = false
        for (let index = 0; index < 6; index++) {
          if (rollScore.results[index] === 2) {
            if (!firstPairFound) {
              rollScore.minor = index + 1
              firstPairFound = true
            }
            else {
              rollScore.major = index + 1
            }
          }
        }
      }
    
      if (rollScore.pairs === 1) {
        for (let index = 0; index < 6; index++) {
          if (rollScore.results[index] === 2) {
            if (rollScore.fullHouse) {
              rollScore.minor = index + 1
            }
            else {
              rollScore.major = index + 1
            }
            break
          }
        }
      }

      rollScore.scorecard.ones = rollScore.results[0] * 1
      rollScore.scorecard.twos = rollScore.results[1] * 2
      rollScore.scorecard.threes = rollScore.results[2] * 3
      rollScore.scorecard.fours = rollScore.results[3] * 4
      rollScore.scorecard.fives = rollScore.results[4] * 5
      rollScore.scorecard.sixes = rollScore.results[5] * 6
      rollScore.scorecard.threeOfAKind = rollScore.threeOfAKind * rollScore.chance
      rollScore.scorecard.fourOfAKind = rollScore.fourOfAKind * rollScore.chance
      rollScore.scorecard.fullHouse = rollScore.fullHouse * 25
      rollScore.scorecard.smallStraight = rollScore.smallStraight * 30
      rollScore.scorecard.largeStraight = rollScore.largeStraight * 40
      rollScore.scorecard.yahtzee = rollScore.yahtzee * 50
      rollScore.scorecard.chance = rollScore.chance
    
     return rollScore
  }