<script>
  import { state } from "../stores/state.js"
  import Die from "../components/Die.svelte"
  export let owner
</script>

<div class="flex justify-center">
  <Die die={$state.dice[owner][0]} {owner} />
  <Die die={$state.dice[owner][1]} {owner} />
  <Die die={$state.dice[owner][2]} {owner} />
  <Die die={$state.dice[owner][3]} {owner} />
  <Die die={$state.dice[owner][4]} {owner} />
</div>
